/* Thank you page styles */

.thank-you-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin: 65px 0;
}

@media screen and (min-width: 501px) {
    .thank-you-title {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .thank-you-title {
        margin-bottom: 40px;
    }
}

.thank-you {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.thank-you p {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0;
}

@media screen and (max-width: 840px) {
    .thank-you p {
        font-size: 26px;
    }
}

@media screen and (max-width: 500px) {
    .thank-you p {
        font-size: 22px;
    }
}

.thank-you img.podcast-preview {
    border-radius: 15px;
    width: 420px;
    height: 420px;
    margin: 30px auto;
}

@media screen and (max-width: 720px) {
    .thank-you img.podcast-preview {
        width: 100%;
        max-width: 335px;
        height: auto;
        margin: 15px auto;
    }
}

.thank-you .charge-credit {
    font-size: 18px;
    font-weight: 300;
}

@media screen and (max-width: 840px) {
    .thank-you .charge-credit {
        font-size: 16px;
    }
}

.thank-you .airpod-right {
    top: -50px;
    right: -4%;
}

.thank-you .airpod-left {
    top: 650px;
    left: -4%;
}

@media screen and (max-width: 1020px) {
    .thank-you .airpod-right,
    .thank-you .airpod-left {
        width: 100px;
    }
    .thank-you .airpod-right {
        right: -1%;
    }
    .thank-you .airpod-left {
        left: -1%;
    }
}

@media screen and (max-width: 820px) {
    .thank-you .airpod-right,
    .thank-you .airpod-left {
        display: none;
    }
}